<template>
    <div class="mobile-account-doc" >
            <div class="account-title" >{{ title }}</div>
            <div class="account-content">
              <p style="font-weight: bold；">
                尊敬的会员，您好！在您开始下一步有关本游戏的游戏帐号删除流程前，我们先为您做出如下特别说明：
              </p>
              <p style="font-weight: bold；">
                删除帐号为不可恢复的操作，帐号删除后您将无法再以本帐号登录和使用我们提供的游戏产品或服务，请您谨慎操作。
              </p>
              <p style="font-weight: bold；">
                您成功删除本游戏的游戏帐号后，我们将对您的个人信息进行删除或匿名化处理，您的游戏日志信息将被保留3个月的时间，且此前已关联该游戏帐号的相关产品与服务将不再关联。
              </p>
              <p style="font-weight: bold；">
                若您经过慎重考虑后仍决定删除本游戏帐号的，请您务必先行仔细阅读和充分理解本《游戏帐号删除协议》（"本协议"），并同意本协议全部内容，本协议由您与本公司游戏服务提供方（以下称"我们"）共同缔结，本协议具有合同效力。您按照我们的删除操作流程开始删除流程的，或者您通过主动点击或勾选的方式同意本删除协议并开始下一步操作的，均视为您已经同意遵守本协议全部内容。
              </p>
              <p style="font-weight: bold；">
                一、游戏帐号删除后您承担的后果
              </p>
              <p style="font-weight: bold；">
                您充分理解并同意：您自愿承担您的游戏帐号删除所（可能）产生的所有后果，前述后果包括但不限于：
              </p>
              <p style="font-weight: bold；">
                1.您将无法登录/使用/继续使用与该游戏帐号相关的所有产品或服务，且该帐号无法恢复，即使您在帐号删除后以相同的手机号码在前述帐号所在区服再次向我们申请注册本公司帐号，该帐号将被默认为全新的用户。
              </p>
              <p style="font-weight: bold；">
                2.游戏帐号删除后，您曾通过该帐号登录、使用该游戏帐号相关产品及服务下的所有内容、信息、数据、记录将会被删除或匿名化处理，前述内容、信息、数据、记录被删除或匿名化处理后您将无法再检索、访问、传输、获取、继续使用、复制和找回，也无权要求我们找回。该等内容、信息、数据或记录包括但不限于：
              </p>
              <p style="font-weight: bold；">
                （1）该帐号下您的个人信息，包括手机号码、实名信息、密码密保和其他个人信息；
              </p>
              <p style="font-weight: bold；">
                （2）该帐号下您曾使用过的所有游戏相关的产品及服务，包括但不限于头像、昵称、游戏帐号内角色及形象、与游戏帐号成长升级相关的所有数据（包括但不限于经验值、荣誉值、声望值、称号等）、虚拟道具、邮件、储值和消费记录、聊天记录、排名等；
              </p>
              <p style="font-weight: bold；">
                （3）该帐号下的其他所有内容、信息、数据、记录。
              </p>
              <p style="font-weight: bold；">
                3.游戏帐号删除后，我们将会对该帐号下的全部权益（如有）做清除处理。据此，我们特别提示您，在您删除本游戏帐号前请您妥善处理、结清您游戏内的相关资产和收益。您理解并同意：通过删除游戏帐号的方式放弃该帐号在游戏相关的产品及服务使用期间已产生但尚未消耗完毕的权益及未来可能产生的预期利益；您同意：不会要求我们恢复或赔偿该等权益或预期利益。该等权益或预期利益包括但不限于：
              </p>
              <p style="font-weight: bold；">
                （1）该帐号下尚未使用的网络游戏虚拟货币、虚拟道具等虚拟物品、资产、储值积分、等级等；
              </p>
              <p style="font-weight: bold；">
                （2）该帐号下您的各类资格、会员权益（包括但不限于游戏月卡等）、连续包月权利等；
              </p>
              <p style="font-weight: bold；">
                （3）该帐号下您已产生但未消耗完毕的其他游戏收益或未来预期的游戏收益权益；
              </p>
              <p style="font-weight: bold；">
                （4）该帐号下您的各类身份权益；
              </p>
              <p style="font-weight: bold；">
                （5）该帐号下您已经购买但未到期的服务内容；
              </p>
              <p style="font-weight: bold；">
                4.本游戏帐号一旦删除，您与我们签署的有关本游戏的用户协议、其他权利义务性档即告终止。但上述协议的终止并不能够减轻或免除您在协议期间内应根据相关法律法规、相关协议、规则等（可能）需要承担的责任包括但不限于可能产生的违约责任、损害赔偿责任及履约义务等。
              </p>
              <p style="font-weight: bold；">
                5.如您的帐号曾因违反国家相关法律法规和/或您与我们签署的有关使用本游戏服务的相关协议、规则（以下称"用户协议"）等相关规定，而被我们采取封帐号及（或）其他处理措施的，为了维护您和其他相关权利人的合法权益所必须，在您游戏帐号删除后我们可能会对与该帐号违规行为有关的游戏数据保留1年。在保留期限内，我们除将该数据用于维护您和其他相关权利人的合法权益外，不会用于其他目的。
              </p>
              <p style="font-weight: bold；">
                6.其他因帐号删除（可能）产生的后果。
              </p>
              <p style="font-weight: bold；">
                二、删除游戏帐号所应具备的条件
              </p>
              <p style="font-weight: bold；">
                在您向我们申请/继续申请删除游戏帐号之前，为保障您的帐号安全和相关权益，您需先行检查，并确保和承诺您申请删除的游戏帐号已经同时满足以下条件，这些条件包括但不限于：
              </p>
              <p style="font-weight: bold；">
                1.该游戏帐号为您本人合法拥有并登录使用，且符合用户协议及相关说明规则中有关游戏帐号的规范。
              </p>
              <p style="font-weight: bold；">
                2.该帐号在申请删除前及申请删除期间没有未处理完毕的纠纷，包括但不限于投诉举报、被投诉举报、国家机关调查、仲裁、诉讼等。
              </p>
              <p style="font-weight: bold；">
                3.该帐号在申请删除前及申请删除期间未处于被检查、被限制的状态。
              </p>
              <p style="font-weight: bold；">
                4.该帐号在最近1个月内无异常登录记录，无帐号敏感操作，该游戏帐号处于安全及正常使用状态，无其他安全风险。
              </p>
              <p style="font-weight: bold；">
                5.该帐号内相关游戏资产和收益已结清或得到妥善处理，特别地，负资产需要结清。请您妥善处理您的游戏收益，若删除时未处理，视为您自愿放弃该等游戏收益。
              </p>
              <p style="font-weight: bold；">
                6.该帐号内无任何未完成的订单或交易，包括但不限于基于该游戏帐号产生的有关网络游戏虚拟货币、虚拟道具、周边商品等的订单等。
              </p>
              <p style="font-weight: bold；">
                7.该帐号内没有已经发起但未完成的抽奖活动。
              </p>
              <p style="font-weight: bold；">
                8.该帐号已解除特殊身份关系（如公会会长/管理员等）。
              </p>
              <p style="font-weight: bold；">
                9.如您曾基于该帐号开通了"自动订阅"/"自动续费"/"连续包月"等包含自动扣费或同类型功能的服务，您应当确保事先取消该等功能和服务。
              </p>
              <p style="font-weight: bold；">
                10.不存在任何由于该等删除而导致的未了结的合同关系和/或其他基于该帐号的存在而产生的或维持的权利义务，以及我们认为删除该帐号会由此产生未了结的权利义务而产生纠纷的情况。
              </p>
              <p style="font-weight: bold；">
                11.本游戏官方公布的其他条件。
              </p>
              <p style="font-weight: bold；">
                上述问题您可首先尝试自行处理，如您无法处理的或在处理过程中有任何疑惑的，可联系我们的客服协助处理。
              </p>
              <p style="font-weight: bold；">
                三、如何删除游戏帐号？
              </p>
              <p style="font-weight: bold；">
                1.您需按照我们的官方管道提供的删除流程和操作指引，在游戏中相关功能设置页面进行删除操作。
              </p>
              <p style="font-weight: bold；">
                2.您已经同意本删除协议的全部内容，同时，您已经检查与确认满足了本协议第二条的要求。
              </p>
              <p style="font-weight: bold；">
                3.我们需要特别说明的是：即使我们判断您符合帐号删除条件，也是基于您的承诺（即您自身确保游戏帐号符合上述删除条件的承诺）进行的形式审查，仍由您自身对游戏帐号符合上述删除条件承担保证责任，我们对此不承担相应的法律责任。
              </p>
              <p style="font-weight: bold；">
                4.您理解并同意，在您申请删除帐号期间，如我们需要对相关交易、投诉或其他与您的帐号相关的事项进行核实的，或者我们有其他合理的理由（包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等）的，我们有权冻结您的帐号删除申请并暂停为您提供删除服务而无需另行得到您的同意。
              </p>
              <p style="font-weight: bold；">
                四、其他
              </p>
              <p style="font-weight: bold；">
                1.本协议为用户协议（"主协议"）组成部分，本协议未尽事宜适用主协议。本协议与主协议不一致的，以本协议为准。
              </p>
              <p style="font-weight: bold；">
                2.本协议的成立、生效、履行、解释及纠纷解决，适用本约之解释以中华民国法令为准据法为准。
              </p>
              <p style="font-weight: bold；">
                3.若您和我们之间因本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至台湾台北地方法院为第一审管辖法院仲裁解决，仲裁是终局的，对双方均具有效力。
              </p>
              <p style="font-weight: bold；">
                4.联系我们：如在帐号删除过程中有任何问题您无法处理的或在处理过程中有任何疑惑的，可联系我们的客服协助处理。
              </p>
            </div>
    </div>
</template>

<script>

export default {
  name: 'accountDoc',
  data () {
    return {
      title: '竹子互联游戏帐号删除协议',
      content: ''
    }
  },
  mounted () {

  },
  created () {},
  methods: {
  }
}
</script>

<style lang="scss">
  @import "@/assets/mobile/account_doc.scss";
  @import "@/assets/mobile/index.scss";
</style>
