var storage = {
  pre: ' tw_',
  support: true,
  checked: false,
  get: function (name) {
    name = this.pre + name
    var v
    this.localStorageSupport()
    if (!this.support) {
      v = this.getCookie(name)
    } else {
      v = this.localStorageGet(name)
    }
    if (v === null) {
      return v
    }
    if (name.indexOf('obj') >= 0) {
      try {
        return JSON.parse(v)
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return v
    }
  },
  set: function (name, val) {
    name = this.pre + name
    if (name.indexOf('obj') >= 0) {
      val = JSON.stringify(val)
    }
    this.localStorageSupport()
    if (!this.support) {
      this.setCookie(name, val)
    } else {
      this.localStorageSet(name, val)
    }
  },
  del: function (name) {
    name = this.pre + name
    this.localStorageSupport()
    if (!this.support) {
      this.delCookie(name)
    } else {
      this.localStorageRemove(name)
    }
  },
  localStorageSet: function (key, value) {
    if (this.localStorageGet(key) !== null) {
      this.localStorageRemove(key)
    }
    localStorage.setItem(key, value)
  },
  localStorageGet: function (key) {
    var v = localStorage.getItem(key)
    return v === undefined ? null : v
  },
  localStorageRemove: function (key) {
    localStorage.removeItem(key)
  },
  localStorageSupport () {
    if (this.checked) {
      return
    }
    this.checked = true
    if (!localStorage) {
      this.support = false
      return
    }
    var testKey = 'localsupport'
    try {
      var v = 'test'
      localStorage.setItem(testKey, v)
      var a = localStorage.getItem(testKey)
      if (a !== v) {
        this.support = false
        return
      }
      localStorage.removeItem(testKey)
      this.support = true
      return
    } catch (error) {
      this.support = false
    }
  },
  delCookie (a) {
    var b, c
    b = new Date()
    b.setTime(b.getTime() - 1)
    c = this.getCookie(a)
    if (c != null) {
      document.cookie = a + '=' + c + ';expires=' + b.toGMTString() + ';path=/;domain=.kaguyajoy.com.tw'
    }
  },
  setCookie: function (a, b) {
    var c = new Date()
    c.setTime(c.getTime() + 31536e6)
    document.cookie = a + '=' + escape(b) + ';expires=' + c.toGMTString() + ';path=/;domain=.kaguyajoy.com.tw'
  },
  getCookie: function (a) {
    var b = document.cookie.match(new RegExp('(^| )' + a + '=([^;]*)(;|$)'))
    return b != null ? unescape(b[2]) : null
  }
}

export default storage
