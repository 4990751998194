import service from '@/utils/services'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'

const isMobile = utils.isMobile()
const isIPhone = utils.isIPhone()
const today = utils.getToday()

const state = {
  isMobile: isMobile,
  isIPhone: isIPhone,
  today: today,
  zhuziAppId: window.zhuziAppId,
  basicData: {
    address: '',
    company: '',
    copyright: '',
    description: '',
    keywords: '',
    record_number: '',
    title: ''
  },
  homeAds: [
    { id: 1, name: '1', image: '' },
    { id: 2, name: '2', image: '' }
  ],
  footLinks: [
    { id: 1, name: '隐私政策', url: 'https://static.hfavatar.com/yuanti/static/privacy_protocol_0825.html' },
    { id: 2, name: '服务条款', url: 'https://static.hfavatar.com/yuanti/static/user_protocol_0825.html' },
    { id: 3, name: '关于我们', url: '/about' },
    { id: 4, name: '联系我们', url: 'mailto:zhuzi@zhuziplay.com?subject=contact' }
  ],
  gameUserInfo: {
    user_id: '',
    username: '',
    realname: '',
    mobile: '',
    token: ''
  },
  games: [
    {
      app_id: 1003,
      title: '主力君',
      banner: 'https://static.zhuziplay.com/office/image/cr8d7h2gdos2hidoni.png',
      listImage: '"@/assets/images/game-imge1.jpg',
      desc1:
        '主力君-【好玩有趣的虚拟人应用】是一款充满乐趣和刺激的应用，为您带来全新的体验。您可以自定义虚拟人角色，并通过完成任务赚取佣金。此外，应用内还设有商城功能，让您尽情购物。',
      desc2:
        '【主力君的特色功能】1. 自定义虚拟人角色：根据个人喜好，创建属于您自己的虚拟人角色，让您与之互动。2. 任务赚佣金：通过完成各类任务，赚取丰厚的佣金奖励。3. 商城功能：在主力君的商城中，您可以尽情购物，发现各类精选商品，并享受优惠折扣。',
      desc3: '',
      content:
        '<p>是一家专门为中大型企业做数字化营销的平台，企业只需要将自己的素材上传即可一键完成素材的剪辑，我们会提供各种创意的脚本，文案生成器，电商图效果生成等工具，帮助企业在内容的生成端高效且高质量，通过高质量的内容输出，来帮助企业搭建自己的矩阵，赋能品牌效应，助力企业增长。</p>',
      url: 'https://xzdzm.zhuziplay.com/index'
    }
  ]
}

const getters = {
  isMobile: (state) => state.isMobile,
  isIPhone: (state) => state.isIPhone,
  today: (state) => state.today,
  zhuziAppId: (state) => state.zhuziAppId,
  basicData: (state) => {
    if (state.basicData.copyright === '') {
      let basicData = storage.get('basicData')
      if (basicData !== null) {
        state.basicData = JSON.parse(basicData)
      }
    }
    return state.basicData
  },
  homeAds: (state) => state.homeAds,
  footLinks: (state) => state.footLinks,
  gameUserInfo: (state) => {
    if (state.gameUserInfo.token === '') {
      let gameUserInfo = storage.get('gameUserInfo')
      if (gameUserInfo !== null) {
        state.gameUserInfo = JSON.parse(gameUserInfo)
      }
    }
    return state.gameUserInfo
  },
  storedData: (state) => state.storedData,
  games: (state) => state.games
}

const mutations = {
  updateBasicData(state, params) {
    storage.set('basicData', JSON.stringify(state.basicData))
  },
  updateGameUserInfo(state, params) {
    storage.set('gameUserInfo', JSON.stringify(state.gameUserInfo))
  }
}

const actions = {
  getSiteDetail({ commit }, params) {
    return new Promise((resolve, reject) => {
      service
        .post('/siteConfig/get', { id: window.siteConfigId })
        .then((res) => {
          if (res.data.code === 0) {
            var basicData = {
              address: res.data.data.address,
              company: res.data.data.company,
              copyright: res.data.data.copyright,
              description: res.data.data.description,
              keywords: res.data.data.keywords,
              record_number: res.data.data.record_number,
              title: res.data.data.title
            }
            state.basicData = basicData
            commit('updateBasicData', basicData)
            resolve(res.data.data)
          } else {
            reject(res.data.msg)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getNewsDetail({ commit }, params) {
    return new Promise((resolve, reject) => {
      service
        .post('/information/get', params)
        .then((res) => {
          if (res.data.code === 0) {
            resolve(res.data.data)
          } else {
            reject(res.data.msg)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getBannerList({ commit }, params) {
    return new Promise((resolve, reject) => {
      service
        .post('/banner/list', params)
        .then((res) => {
          if (res.data.code === 0) {
            resolve(res.data.data)
          } else {
            reject(res.data.msg)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  ZhuziUserLogin({ commit }, params) {
    return new Promise((resolve, reject) => {
      service
        .post('/login/account', { act: true, username: params.account, password: params.password })
        .then((res) => {
          if (res.data.code === 0) {
            var gameUserInfo = {
              username: res.data.data.username,
              realname: res.data.data.realname,
              mobile: res.data.data.mobile,
              token: res.data.data.token,
              user_id: res.data.data.user_id
            }
            state.gameUserInfo = gameUserInfo
            commit('updateGameUserInfo', gameUserInfo)
            resolve(res.data.data)
          } else if (res.data.code === 1) {
            resolve(res.data)
          } else {
            reject(res.data.msg)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  ZhuziUserRegister({ commit }, params) {
    return new Promise((resolve, reject) => {
      service
        .post('/reg', { act: true, username: params.account, password: params.password })
        .then((res) => {
          console.log(res)
          if (res.data.code === 0) {
            var gameUserInfo = {
              username: res.data.data.username,
              realname: res.data.data.realname,
              mobile: res.data.data.mobile,
              token: res.data.data.token,
              user_id: res.data.data.user_id
            }
            state.gameUserInfo = gameUserInfo
            commit('updateGameUserInfo', gameUserInfo)
            resolve(res.data.data)
          } else if (res.data.code === 1) {
            resolve(res.data)
          } else {
            reject(res.data.msg)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  UserOut({ commit }, params) {
    state.gameUserInfo = params
    commit('updateGameUserInfo', params)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
