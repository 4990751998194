<template>
    <div id="app" >
        <top-bar/>
        <router-view />
        <foot-bar />
    </div>
  </template>

<script>
import { TopBar, FootBar } from '@/components'
export default {
  name: 'BaseLayout',
  data () {
    return {}
  },
  components: {
    TopBar,
    FootBar
  },
  computed: {

  },

  created () {},
}
</script>
    <!-- @import "@/assets/mobile/index.scss"; -->
<style lang="scss">
  @import "@/assets/styles/index.scss";

</style>
