<template>
    <div class="mobile-account-doc" >
        <div class="account-title" >{{ title }}</div>
        <div class="account-content">
            <p style="font-weight: bold；">※隐私权保护政策的适用范围※</p>
            <p>1.隐私权保护政策内容，包括本公司如何处理在用户使用本公司服务时收集到的身份识别数据，也包括本公司如何处理在商业伙伴与本公司合作时分享的身份识别数据。</p>
            <p>2.隐私权保护政策不适用于本公司以外的公司，也不适用于非本公司所雇用或管理的人员。</p>
            <p style="font-weight: bold；">※资料收集及使用方式※</p>
            <p>1.本公司在您注册本公司帐号、使用本公司的产品或服务、浏览本公司网页、参加宣传活动或赠奖游戏时，本公司会收集您的个人识别数据。本公司也可以从商业伙伴处取得个人资料。</p>
            <p>2.当您在本公司注册时，我们会问及您的姓名、电子邮件地址、性别、生日、居住地址等数据。您在本公司注册成功，并登入使用我们的服务后，我们就会认识您。</p>
            <p>3.本公司会使用数据作以下用途：改进并依建议或需求为您提供的广告及网页服务内容；完成您对某项产品或服务的建议或需求；以及通知您关于特别活动或新产品、新服务之内容。</p>
            <p style="font-weight: bold；">※数据分享及公开方式※</p>
            <p>1.本公司不会向任何人出售或出借您的个人识别数据。</p>
            <p>2.在以下的情况下，本公司会向其他人士或公司提供您的个人识别数据：</p>
            <p>a.与其他人士或公司共享数据前取得您的同意。</p>
            <p>b.需要与其他人士或公司共享您的数据，才能够提供您要求的产品或服务。</p>
            <p>c.向代表本公司提供服务或产品的公司提供数据，以便向您提供产品或服务。（若我们没有事先通知您，这些公司均无权使用我们提供的个人资料，作提供产品或服务以外的其他用途）</p>
            <p>d.应遵守法令或政府机关的要求；或我们发觉您在本公司产品上的行为违反本公司会员公约中的条款或产品、服务的特定使用指南时。</p>
            <p>3.您可以从本公司所提供之互动设定选项，依需求自行选择并设定是否公开您的姓名、电子邮件地址、性别、生日、居住地址等数据。</p>
            <p style="font-weight: bold；">※修改会员个人帐号数据的权利※</p>
            <p>1.本公司赋予您修改个人在本公司中会员资料的权利，包括接受本公司通知您特别活动或新产品的决定权。</p>
            <p>2.本网会依照隐私权政策保护所有会员的隐私，不管是申请帐号、个人资料或所储存的网站、游戏数据，除了可能涉及违法、侵权、或违反使用条款、或经本人同意以外，本公司不会任意监视、增删、修改或关闭，或将个人资料内容交予第三者，包括赞助之广告厂商。</p>
            <p style="font-weight: bold；">※保全※</p>
            <p>1.为保障您的隐私及安全，您在本公司的帐号数据会善尽保护。</p>
            <p>2.当您进入会员资料编辑及部分拥有个人资料及隐私页面时，本公司会要求您输入帐号密码识别数据确认身份。</p>
            <p style="font-weight: bold；">※隐私权保护政策修订※</p>
            <p>本公司可以不时修订本政策。当我们在使用个人资料的规定上作出大修改时，我们会在网页上张贴告示，并通知您相关修改事项。</p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'privacyDoc',
  data () {
    return {
      title: '竹子互联隐私权保护政策',
      content: ''
    }
  },
  mounted () {

  },
  created () {},
  methods: {
  }
}
</script>

<style lang="scss">
  @import "@/assets/mobile/account_doc.scss";
  @import "@/assets/mobile/index.scss";
</style>
