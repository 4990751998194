<template>
  <router-view :key="key"></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'app',
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['gameUserInfo','isMobile', 'basicData']),
    key () {
      return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
    }
  },
  created () {
    this.getSiteDetail()
  },
  methods: {
    ...mapActions(['getSiteDetail']),
  }
}
</script>
