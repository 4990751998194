import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/filter' // global filter
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import VueClipboard from 'vue-clipboard2'

Vue.use(VueAwesomeSwiper)
Vue.use(VueClipboard)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
