<template>
  <div class="mobile-footbar" v-if="isMobile">
    <div class="img">
      <img src="@/assets/images/logo.png" alt />
      <img src="@/assets/images/icon.png" alt />
    </div>
    <p>
      <a v-for="(item, index) in footLinks" :key="index" :href="item.url">{{ item.name }}</a>
    </p>
    <p>公司地址：上海市浦东新区灵岩南路1400弄11号三层306室</p>
    <p>©2023 上海元梯科技有限责任公司</p>
    <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: white">沪ICP备2023012102号-1</a>
  </div>
  <div class="footbar" v-else>
    <div class="left">
      <div class="img">
        <img src="@/assets/images/app_logo.png" alt />
        <img src="@/assets/images/icon.png" alt />
      </div>
      <div class="link">
        <a v-for="(item, index) in footLinks" :key="index" :href="item.url">{{ item.name }}</a>
      </div>
    </div>
    <div class="foot_center"></div>
    <div class="right">
      <p>公司地址：上海市浦东新区灵岩南路1400弄11号三层306室</p>
      <p>©2023 上海元梯科技有限责任公司</p>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: white">沪ICP备2023012102号-1</a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'footBar',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isMobile', 'basicData', 'footLinks'])
  }
}
</script>

<style lang="scss"></style>
