import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import store from '../store'

Vue.filter('ymday', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (!isNaN(dataStr)) {
    dataStr = parseInt(dataStr)
  }
  return moment(dataStr).format(pattern)
})

Vue.filter('awardCodeType', function (tp) {
  if (tp < 1 || tp > 5) {
    tp = 1
  }
  let arr = [
    '預約獎勵:',
    '商店預約獎勵:',
    '邀請1名好友獎勵:',
    '邀請3名好友獎勵:',
    '答題獎勵：',
  ]
  return arr[tp - 1]
})

Vue.filter('showQuestionIndex', function (tp) {
  let arr = [
    '一',
    '二',
    '三',
    '四',
    '五',
    '六'
  ]
  return arr[tp - 1]
})

Vue.filter('gamesName', function (val) {
  val = parseInt(val)
  var index = store.state.home.games.findIndex(item => {
    if (item.id === val) {
      return item
    }
  })
  if (index >= 0) {
    return store.state.home.games[index].name
  } else {
    return val
  }
})
